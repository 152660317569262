// Stylesheets
import './main.scss';

// Imports all component based scss files.
import './components/**/*.scss';
import './components/flippable-card/flippable-card';
import './components/image-map/image-map';
import './components/tabs/tabs';
import './components/swiper/swiper';
import './components/injectionspoint/injectionspoint';

import initializeCustomSelect from './components/custom-select/custom-select';

// import favicon here.

declare global {
  interface Window {
    Bus: any; // event bus
    OneTrust: any;
    _tnsInstances: any; //carousel
    _tabbyInstances: any; //tabs
    _plyrInstances: any; //vimeo embed
    _badgerInstances: any; //accordion
    _swiperCarouselInstances: any; // swiper carousels
    _ssSelectInstance: any; // Slim Select (Custom Select) Instances
  }
}

const CONSTANTS = {
  header: {
    mobileNavigation: {
      closeIconId: 'header__menu-mobile-close',
      menuTriggerId: 'header__menu-mobile-trigger',
    },
    stickyClassName: 'header--sticky',
    eyebrowBrands: {
      triggerId: 'aesthetics-brands-trigger',
      closeId: 'aesthetics-brands-close',
    },
  },
};

// This function will recreate custom table for mobile devices
const setTableForMobile = function () {
  const tableEl = document.querySelectorAll('table');
  if (tableEl.length) {
    tableEl.forEach(item => {
      const thEls = item.querySelectorAll('thead th') as NodeListOf<Element>;
      const tdLabels: string[] = [];

      Array.from(thEls).forEach(el => {
        if (el instanceof HTMLElement) {
          tdLabels.push(el.innerHTML);
        }
      });

      item.querySelectorAll('tbody tr').forEach(tr => {
        Array.from(tr.children).forEach((td, ndx) => {
          td.innerHTML = `<span class="title">${tdLabels[ndx]}</span><span class="content">${td.innerHTML}</span>`;
          // td.setAttribute('label', tdLabels[ndx]);
        });
      });
    });
  }
};

(() => {
  // This function will help to close the opened menu on user interaction
  const menuCloseHandler = (closeIconId: string, menuTriggerId: string) => {
    const menuTrigger = document.querySelector(
      '#' + menuTriggerId
    )! as HTMLElement;
    const closeIconBtn = document.querySelector(
      '#' + closeIconId
    )! as HTMLElement;

    closeIconBtn?.addEventListener('click', e => {
      e.preventDefault();
      menuTrigger?.click();
    });
  };

  // closes patient safety, allergan brand and mobile menu navigation
  const closeMenuHandler = () => {
    // Close mobile navigation
    menuCloseHandler(
      CONSTANTS.header.mobileNavigation.closeIconId,
      CONSTANTS.header.mobileNavigation.menuTriggerId
    );

    //Close Allergan Aesthetics Brands
    menuCloseHandler(
      CONSTANTS.header.eyebrowBrands.closeId,
      CONSTANTS.header.eyebrowBrands.triggerId
    );
  };

  // manages the sticky nature of the header
  const handleScrollMenu = () => {
    const header = document.querySelector('header.header') as HTMLElement;
    console.log('header', header)
    window.addEventListener('scroll', () => {
      if (window.scrollY > 0) {
        header.classList.add(CONSTANTS.header.stickyClassName);
      } else {
        header.classList.remove(CONSTANTS.header.stickyClassName);
      }
    });
  };

  // The carousel needs animation for the indicators. This function adds the animation functionality for all the carousels
  // Add a class 'aaaem-carousel--static-indicators' to the carousel to avoid adding the animation
  const setCarouselAttributes = () => {
    if (window._tnsInstances) {
      const values = Object.values(window._tnsInstances);
      if (values?.length) {
        values.forEach(inst => {
          // @ts-ignore;
          const carouselInfo = inst?.getInfo?.();
          if (carouselInfo) {
            try {
              const container = carouselInfo.container;
              const carouselWrapper = container?.closest?.('.aaaem-carousel');

              // skip the animation if the indicators animation is not needed
              if (
                carouselWrapper.classList?.contains(
                  'aaaem-carousel--static-indicators'
                )
              ) {
                return;
              }

              // skip the animation if the indicators are thumbnails
              if (
                carouselWrapper.getAttribute?.('data-nav-as-thumbnails') ===
                'true'
              ) {
                carouselWrapper?.classList?.add(
                  'aaaem-carousel--static-indicators'
                );
                return;
              }

              // @ts-ignore;
              const carouselIndicators = carouselInfo.navContainer;
              if (carouselIndicators instanceof HTMLElement) {
                carouselWrapper?.classList?.add('aaaem-carousel--show');
                const indicatorWidth = 100 / carouselInfo.slideCount;
                carouselIndicators.style.setProperty(
                  '--width',
                  indicatorWidth + '%'
                );
                // @ts-ignore;
                inst.events.on('transitionStart', e => {
                  const indicatorPos = e.navCurrentIndex * indicatorWidth;
                  carouselIndicators.style.setProperty(
                    '--left',
                    indicatorPos + '%'
                  );
                });
              } else {
                carouselWrapper?.classList?.add(
                  'aaaem-carousel--static-indicators'
                );
              }
            } catch {
              const container = carouselInfo?.container;
              const carouselWrapper = container?.closest?.('.aaaem-carousel');
              carouselWrapper?.classList?.add(
                'aaaem-carousel--static-indicators'
              );
            }
          }
        });
      }
    }
  };

  // on home carousel if video is being played, and the carousel slide is changed, pause the video
  const handleHomeTestimonialCarousel = () => {
    const carouselInst = window._tnsInstances?.['testimonial-carousel'];
    if (carouselInst) {
      carouselInst.events.on('transitionStart', e => {
        const prevSlide = e?.slideItems?.[e?.indexCached];
        const prevEmbedId = prevSlide?.querySelector('.emu-video-plyr')?.id;
        const prevEmbedPlyrInstance = window._plyrInstances?.[prevEmbedId];
        prevEmbedPlyrInstance?.stop?.();

        const currentSlide = e?.slideItems?.[e?.index];
        const curEmbedId = currentSlide?.querySelector('.emu-video-plyr')?.id;
        const curEmbedPlyrInstance = window._plyrInstances?.[curEmbedId];
        curEmbedPlyrInstance?.increaseVolume?.(100);
        curEmbedPlyrInstance?.play?.();
      });
    }
  };

  // in the results page this function will scroll to the top of the patients list when show less button is clicked
  const scrollResultsPatientCards = () => {
    const patientCardWrapper = document.querySelector(
      '.results-card__wrapper'
    ) as HTMLElement;
    if (patientCardWrapper) {
      window.Bus?.on('emu-button:click', e => {
        if (e.id === 'see-more-patients' && e.toggleOn === false) {
          patientCardWrapper.scrollIntoView();
        }
      });
    }
  };

  // Opens modal in results page when patient grid item is clicked in results page
  const showResultsModal = () => {
    const results = document.querySelectorAll(
      '.results-card__grid .results-card__item'
    );
    const showModalBtn = document.querySelector(
      '.show-modal-btn'
    ) as HTMLElement;
    const resultsCarouselInst =
      window._tnsInstances?.['carousel-result-slider'];
    if (resultsCarouselInst && results?.length) {
      results.forEach((item, index) => {
        item.addEventListener('click', e => {
          e.preventDefault();
          showModalBtn?.click();
          resultsCarouselInst.goTo?.(index);
        });
      });
    }
  };

  // adds class to the parent of cards that has card--show to show the cards by default when the page is loaded on mobile
  // on mobile show first 6 cards, and clicking on show more shows the rest of the cards
  // toggling of js-toggle-on class is handled by aaaem-common, based on which the cards will be shown via css, with help of card-container--show class
  const handleResultsMobileCards = () => {
    const cardDiv = document.querySelectorAll(
      '.card-main-wrapper .card-wrapper .card'
    );
    if (cardDiv) {
      cardDiv.forEach(item => {
        if (item.classList.contains('card--show')) {
          item.parentElement?.classList.add('card-container--show');
        }
      });
    }
  };

  // stops plyr video for a give id
  const stopPlyrVideo = id => {
    const prevEmbedPlyrInstance = window._plyrInstances?.[id];
    if (prevEmbedPlyrInstance?.playing) {
      prevEmbedPlyrInstance?.stop?.();
    }
  };

  // In Results page, keeps the results carousel inside the modal, in sync with the results trigger thumbnail carousel
  // adds click events to the carousel trigger slider element
  // makes sure that the videos are not playing when slide is changed, or when the modal is closed
  const handleResultsModal = () => {
    const resultsInst = window._tnsInstances?.['carousel-result-slider'];
    const resultsTriggerInst =
      window._tnsInstances?.['carousel-result-trigger-slider'];
    if (resultsInst && resultsTriggerInst) {
      const triggerSliderInfo = resultsTriggerInst.getInfo();
      const slideItems =
        triggerSliderInfo?.slideItems as NodeListOf<HTMLElement>;
      const slideItemsArr = Array.from(slideItems);
      const moreVideosCta = document.querySelector(
        '.results__ba-more-videos-cta'
      ) as HTMLButtonElement;

      // moving the results trigger carousel, when results carousel changed its state
      // also changing the class of the results trigger carousel active item accordingly
      // pausing any videos that are playing
      resultsInst.events.on('indexChanged', e => {
        const { index } = e;
        resultsTriggerInst.goTo(index);

        slideItemsArr.forEach((e, i) => {
          if (i === index) {
            e.classList.add('active-slide');
          } else {
            e.classList.remove('active-slide');
          }
        });

        const prevSlide = e?.slideItems?.[e?.indexCached];
        const prevEmbedId = prevSlide?.querySelector('.emu-video-plyr')?.id;

        if (prevEmbedId) {
          stopPlyrVideo(prevEmbedId);
        }
      });

      // when the modal is closed, making sure that the video is stopped
      window.Bus.on('emu-modal:close', ({ id }) => {
        const resultInstInfo = resultsInst?.getInfo();
        if (id === 'results-modal' && resultInstInfo) {
          const curSlide = resultInstInfo?.slideItems?.[resultInstInfo.index];
          const curEmbedId = curSlide?.querySelector('.emu-video-plyr')?.id;
          stopPlyrVideo(curEmbedId);
        }
      });

      // when items inside the results trigger carousel are clicked, changing the state of results carousel accordingly
      slideItemsArr.forEach((el, i) => {
        el.addEventListener('click', () => {
          moreVideosCta?.click();
          resultsInst.goTo(i);
        });
      });
    }
  };

  // initializing the custom select dropdown for all select elements with parent custom-select class
  const initCustomSelect = () => {
    const selects: NodeListOf<HTMLSelectElement> = document.querySelectorAll(
      '.custom-select select'
    );

    if (selects?.length) {
      selects.forEach((select: HTMLSelectElement) => {
        initializeCustomSelect?.(select, {
          showSearch: false,
          contentLocation: select.closest('.custom-select'),
        });
      });
    }
  };

  // This function manages options on the practice support page
  // on clicking form select dropdown, it will show/hide respective query content
  const handleStepQueries = () => {
    const formSelect = document.querySelector(
      '.practice-support__steps-main select'
    );

    const contentList = document.querySelectorAll(
      '.practice-support__steps-query-content'
    );

    if (contentList?.length && formSelect) {
      // adding show class to the first available content option
      contentList[0].classList.add(
        'practice-support__steps-query-content--show'
      );

      // when the dropdown value is changed, changing the visibility of the corresponding section
      formSelect.addEventListener('change', e => {
        if (e.target) {
          const selectedVal = e.target as HTMLButtonElement;
          if (selectedVal.value) {
            contentList.forEach(item => {
              const contentId = item.id;
              if (contentId === selectedVal.value) {
                item.classList.add(
                  'practice-support__steps-query-content--show'
                );
              } else {
                item.classList.remove(
                  'practice-support__steps-query-content--show'
                );
              }
            });
          }
        }
      });
    }
  };

  const init = () => {
    console.log('test??')
    initCustomSelect();
    setTableForMobile();
    closeMenuHandler();
    handleScrollMenu();
    setCarouselAttributes();

    // Home page related functionalities
    const isHome = document.querySelector('#home');
    if (isHome) {
      handleHomeTestimonialCarousel();
    }

    // Results page related functionalities
    const isResults = document.querySelector('#results');
    if (isResults) {
      scrollResultsPatientCards();
      showResultsModal();
      handleResultsMobileCards();
      handleResultsModal();
    }

    // Practice Support page related functionalities
    const isPracticeSupport = document.querySelector('#practice-support');
    if (isPracticeSupport) {
      handleStepQueries();
    }

    if (window.Bus) {
      // listen to events here
    }
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
