import Swiper from 'swiper';
import 'swiper/swiper.scss';

(() => {
  const initSwiperCarousels = () => {
    // initiating swiper carousel for all elements that has class `swiper-carousel`
    // Here is the structure to be followed.
    // 1. Author a container component, and add the class `swiper-carousel`. Add optional class swiper-carousel--disabled-in-mobile to disable the carousel in mobile view
    // 2. Author another container component inside `swiper-carousel` container, and add the class `swiper-wrapper`
    // 3. Author containers inside `swiper-wrapper` which will work ask carousel slides
    // 4. Author slide content inside the slide containers authored in #3
    // Instance of the swiper carousel is available in window variable window._swiperCarouselInstances

    // Disabling in author
    const isAuthor = document.querySelector('.cq-Editable-dom--container');
    if (isAuthor) return;

    const swiperCarousels = document.querySelectorAll('.swiper-carousel');
    if (swiperCarousels.length) {
      swiperCarousels.forEach(swiperCarousel => {
        const swiperCarouselId = swiperCarousel.id;
        const container = swiperCarousel.querySelector('.container');
        container?.classList.add('swiper', `${swiperCarouselId}-swiper`);
        const swiperWrapper = swiperCarousel.querySelector('.swiper-wrapper');
        const slides = swiperWrapper?.children;

        if (slides) {
          Array.from(slides).forEach(slide => {
            slide.classList.add('swiper-slide');
          });
          const disabledInMobile = swiperCarousel.classList.contains(
            'swiper-carousel--disabled-in-mobile'
          );
          let winWidth = window.innerWidth;

          window._swiperCarouselInstances =
            window._swiperCarouselInstances || {};

          const initiateCarousel = () => {
            window._swiperCarouselInstances[swiperCarouselId] = new Swiper(
              `.${swiperCarouselId}-swiper`,
              {
                spaceBetween: 22,
                slidesPerView: 'auto',
                slidesOffsetAfter: 20,
                slidesOffsetBefore: 20,
                breakpoints: {
                  1440: {
                    slidesOffsetAfter: 80,
                    slidesOffsetBefore: 80,
                  },
                },
              }
            );
          };

          if (disabledInMobile) {
            window.addEventListener('resize', () => {
              const curWidth = window.innerWidth;
              if (curWidth !== winWidth) {
                winWidth = curWidth;
                const curInstance =
                  window._swiperCarouselInstances[swiperCarouselId];
                if (winWidth >= 1024) {
                  if (!curInstance || curInstance?.destroyed) {
                    initiateCarousel();
                  }
                } else {
                  if (curInstance && curInstance?.destroyed !== true) {
                    curInstance.destroy();
                  }
                }
              }
            });

            if (winWidth >= 1024) {
              initiateCarousel();
            }
          } else {
            initiateCarousel();
          }
        }
      });
    }
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', initSwiperCarousels);
  } else {
    initSwiperCarousels();
  }
})();
